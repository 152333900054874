<template>
  <footer>
    <section class="section section-md bg-color-secondary-lighter">
      <div class="container">
        <div class="row">
          <div class="col-sm-15">
            <strong class="h4 mt-0">{{ $t('footer_label_menu') }}</strong>
            <ul class="list-unstyled list-footer-menu">
              <li>
                <router-link class="link" to="/">{{ $t('footer_btn_home') }}</router-link>
              </li>
              <li>
                <span @click="url.openAdmin(env)" class="link" to="admin">{{ $t('footer_btn_administration') }}</span>
              </li>
            </ul>
          </div>
          <div class="col-sm-15">
            <strong class="h4 mt-sm-0">{{ $t('footer_label_help') }}</strong>
            <ul class="list-unstyled list-footer-menu">
              <li>
                <router-link class="link" :to="{ name: `terms-conditions` }">
                  {{ $t('footer_btn_termsConditions') }}
                </router-link>
              </li>
              <li>
                <router-link class="link" :to="{ name: `privacy-policy` }">
                  {{ $t('footer_btn_privacyPolicy') }}
                </router-link>
              </li>
              <li>
                <router-link class="link" :to="{ name: `dpa` }">{{ $t('footer_btn_dpa') }}</router-link>
              </li>
              <li>
                <router-link class="link" :to="{ name: `security-declaration` }">
                  {{ $t('footer_btn_securityDeclaration') }}
                </router-link>
              </li>
            </ul>
          </div>
          <div class="col-sm-15">
            <strong class="h4 mt-sm-0">{{ $t('footer_label_contact') }}</strong>
            <ul class="list-unstyled list-footer-menu">
              <li>
                {{ settings.general.complex_address_line_1 }}
              </li>
              <li>
                {{ settings.general.complex_address_line_2 }}
              </li>
              <li class="mt-sm-3">
                {{ settings.general.complex_contact_phone }}
              </li>
            </ul>
          </div>
          <div class="col-sm-15 mt-5 mt-sm-0">
            <GmapMap
              :center="{ lat: mapMarker.lat, lng: mapMarker.lng }"
              :zoom="15"
              :options="{ styles: styles }"
              map-type-id="terrain"
            >
              <gmap-custom-marker :marker="mapMarker">
                <div class="map-marker-bg">
                  <img src="../../assets/images/map-marker.svg" alt="marker" class="img-fluid" />
                </div>
              </gmap-custom-marker>
            </GmapMap>
          </div>
        </div>
      </div>
    </section>
    <section class="section section-xs bg-color-secondary-light">
      <div class="container">
        <div class="row">
          <div class="col-sm-60 text-center">
            <p>© Sharry Europe ©{{ new Date().getFullYear() }}. {{ $t('footer_label_allRightsReserved') }}</p>
          </div>
        </div>
      </div>
    </section>
  </footer>
</template>

<script>
import GmapCustomMarker from 'vue2-gmap-custom-marker'

// Helpers
import url from '@/helpers/url'
import { mapGetters } from 'vuex'

export default {
  name: 'Footer',
  data() {
    return {
      styles: [
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [
            {
              color: '#e9e9e9',
            },
            {
              lightness: 17,
            },
          ],
        },
        {
          featureType: 'landscape',
          elementType: 'geometry',
          stylers: [
            {
              color: '#f5f5f5',
            },
            {
              lightness: 20,
            },
          ],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#ffffff',
            },
            {
              lightness: 17,
            },
          ],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#ffffff',
            },
            {
              lightness: 29,
            },
            {
              weight: 0.2,
            },
          ],
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry',
          stylers: [
            {
              color: '#ffffff',
            },
            {
              lightness: 18,
            },
          ],
        },
        {
          featureType: 'road.local',
          elementType: 'geometry',
          stylers: [
            {
              color: '#ffffff',
            },
            {
              lightness: 16,
            },
          ],
        },
        {
          featureType: 'poi',
          elementType: 'geometry',
          stylers: [
            {
              color: '#f5f5f5',
            },
            {
              lightness: 21,
            },
          ],
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry',
          stylers: [
            {
              color: '#dedede',
            },
            {
              lightness: 21,
            },
          ],
        },
        {
          elementType: 'labels.text.stroke',
          stylers: [
            {
              visibility: 'on',
            },
            {
              color: '#ffffff',
            },
            {
              lightness: 16,
            },
          ],
        },
        {
          elementType: 'labels.text.fill',
          stylers: [
            {
              saturation: 36,
            },
            {
              color: '#333333',
            },
            {
              lightness: 40,
            },
          ],
        },
        {
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'transit',
          elementType: 'geometry',
          stylers: [
            {
              color: '#f2f2f2',
            },
            {
              lightness: 19,
            },
          ],
        },
        {
          featureType: 'administrative',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#fefefe',
            },
            {
              lightness: 20,
            },
          ],
        },
        {
          featureType: 'administrative',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#fefefe',
            },
            {
              lightness: 17,
            },
            {
              weight: 1.2,
            },
          ],
        },
      ],
      url,
    }
  },
  computed: {
    ...mapGetters(['env', 'settings']),

    mapMarker() {
      return {
        lat: Number(parseFloat(this.env.VUE_APP_BUILDING_GPS_LAT).toFixed(6)),
        lng: Number(parseFloat(this.env.VUE_APP_BUILDING_GPS_LNG).toFixed(6)),
      }
    },
  },
  components: {
    GmapCustomMarker,
  },
}
</script>
