import store from '@/store'

export function setAdminColor() {
  const root = document.querySelector(':root')
  const env = store.getters['env']

  const colorPrimary = env.VUE_APP_COLOR

  if (!colorPrimary) {
    return
  }

  root.style.setProperty('--color-primary', colorPrimary)
  root.style.setProperty('--color-primary-lighter', lightenDarkenColor(colorPrimary, 20))
  root.style.setProperty('--color-primary-light', lightenDarkenColor(colorPrimary, 10))
  root.style.setProperty('--color-primary-dark', lightenDarkenColor(colorPrimary, -10))
  root.style.setProperty('--color-primary-darker', lightenDarkenColor(colorPrimary, -20))

  function hexToRgbA(hex, alpha) {
    var c

    c = hex.substring(1).split('')

    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }

    c = '0x' + c.join('')

    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ', ' + alpha + ')'
  }

  function lightenDarkenColor(color, amount) {
    let colorWithoutHash = color.replace('#', '')
    if (colorWithoutHash.length === 3) {
      colorWithoutHash = colorWithoutHash
        .split('')
        .map(c => `${c}${c}`)
        .join('')
    }

    const getColorChannel = substring => {
      let colorChannel = parseInt(substring, 16) + amount
      colorChannel = Math.max(Math.min(255, colorChannel), 0).toString(16)

      if (colorChannel.length < 2) {
        colorChannel = `0${colorChannel}`
      }

      return colorChannel
    }

    const colorChannelRed = getColorChannel(colorWithoutHash.substring(0, 2))
    const colorChannelGreen = getColorChannel(colorWithoutHash.substring(2, 4))
    const colorChannelBlue = getColorChannel(colorWithoutHash.substring(4, 6))

    return `#${colorChannelRed}${colorChannelGreen}${colorChannelBlue}`
  }
}
