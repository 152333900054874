import Vue from 'vue'
import Vuex from 'vuex'

import ls from 'localstorage-slim'

import settingsAPI from '@/api/settings'

Vue.use(Vuex)

const setLocalStorage = (key, value, ttl = 86400) => {
  ls.set(key, value, { ttl, encrypt: true })
}

export default new Vuex.Store({
  state: {
    language: '',
    token: null,
    settings: null,
    env: ls.get('envLandingSettings', { decrypt: true }),
    translations: {},
    version: process.env.VUE_APP_VERSION || null,
  },
  mutations: {
    LOAD_ENV_SETTINGS(state, payload) {
      state.env = payload
      setLocalStorage('envLandingSettings', payload)
    },
    SET_LANGUAGE(state, language) {
      state.language = language
    },
    SET_SETTINGS(state, settings) {
      state.settings = settings
    },
    STORE_TRANSLATIONS(state, translations) {
      state.translations = translations
    },
    SET_TOKEN(state, token) {
      state.token = token
    },
  },
  actions: {
    language(context, language) {
      context.commit('SET_LANGUAGE', language)
    },
    token(context, token) {
      context.commit('SET_TOKEN', token)
    },

    async loadEnvSettings({ commit }) {
      const response = await settingsAPI.getEnvironmentSettings()
      commit('LOAD_ENV_SETTINGS', response.data)

      return response.data
    },

    storeTranslations({ commit }, translations) {
      commit('STORE_TRANSLATIONS', translations)
    },
  },

  getters: {
    token: state => state.token,
    env: state => state.env,
    language: state => state.language,
    settings: state => state.settings,
    translations: state => state.translations,
    version: state => state.version,
  },
})
