<template>
  <header>
    <nav class="navbar navbar-expand fadeIn animated" id="menu-wrapper">
      <div class="container-fluid header-container">
        <router-link class="navbar-brand" to="/">
          <img :src="logo" alt="Logo" class="img-fluid" />
        </router-link>
        <div id="headerMenu">
          <ul class="navbar-nav ml-sm-auto justify-content-end">
            <li class="nav-item text-uppercase" v-for="(language, index) in languages" :key="index">
              <span class="nav-link" :class="{ active: locale === language }" @click="setLanguage(language)">
                {{ $t(`menu_btn_${language}`) }}
              </span>
            </li>
            <li class="nav-item nav-item-btn">
              <div class="nav-link" @click="url.openAdmin(env)">
                <span class="nav-item-btn-text">
                  Portal
                  <sup>wx</sup>
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
// Helpers
import url from '@/helpers/url'
import { mapGetters } from 'vuex'

export default {
  name: 'Header',
  data() {
    return {
      url,
    }
  },
  mounted() {
    this.checkUrlForLang()
  },
  computed: {
    ...mapGetters(['env', 'language']),

    languages() {
      return this.env.VUE_APP_LANGUAGES.length ? this.env.VUE_APP_LANGUAGES.split(',') : []
    },
    locale() {
      return this.language
    },

    logo() {
      return `${this.env.VUE_APP_ASSETS}/logo.svg`
    },
  },
  methods: {
    checkUrlForLang() {
      if (this.$route.query.lang) this.setLanguage(this.$route.query.lang)
    },

    setLanguage: function (lang) {
      const messages = this.$i18n.getLocaleMessage(lang)
      this.$i18n.setLocaleMessage(lang, messages)
      this.$i18n.locale = lang
      localStorage.setItem('landing_language', lang)
      this.$store.dispatch('language', lang)
    },
  },
}
</script>
