import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from './api'
import VueGtm from 'vue-gtm'
import * as momentDefault from 'moment-timezone'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueMeta from 'vue-meta'
import { isEmpty } from 'lodash-es'

Vue.use(VueMeta)

// Localization
import { i18nLocale } from '@/i18n'

import { useFavicon } from '@vueuse/core/index'
import './assets/scss/main.scss'

Vue.config.debug = true
 Vue.config.silent =true
  Vue.config.devtools = true
  Vue.config.productionTip = true

Vue.prototype.$api = api

function loadEnvSettings() {
  return new Promise(function (resolve) {
    const response = store.dispatch('loadEnvSettings')
    resolve(response)
  })
}

async function runApp() {
  let envSettings
  const appVersion = store.getters['version']
  const currentAppVersion = localStorage.getItem('appVersion')

  if (!isEmpty(appVersion) && !isEmpty(currentAppVersion) && currentAppVersion !== appVersion) {
    envSettings = await loadEnvSettings()
    localStorage.setItem('appVersion', appVersion)
  } else {
    if (!isEmpty(appVersion) && isEmpty(currentAppVersion)) {
      localStorage.setItem('appVersion', appVersion)
    }
    envSettings = store.getters['env'] ?? (await loadEnvSettings())
  }

  useFavicon('favicon.svg', {
    baseUrl: `${envSettings.VUE_APP_ASSETS}/`,
    rel: 'icon',
  })

  const i18n = await i18nLocale()

  Vue.use(VueGtm, {
    id: envSettings.VUE_APP_GTM_ID,
    enabled: true,
    debug: false,
    loadScript: true,
    vueRouter: router,
  })

  Vue.use(VueGoogleMaps, {
    load: {
      key: store.getters['env'].VUE_APP_GOOGLE_MAP_KEY,
      libraries: 'places',
    },
  })
  const defaultLang = localStorage.getItem('landing_language')
    ? localStorage.getItem('landing_language')
    : envSettings.VUE_APP_I18N_LOCALE

  const zone = envSettings.VUE_APP_TIMEZONE ?? momentDefault.tz.guess(true)
  Vue.prototype.$moment = momentDefault.tz.setDefault(zone)

  await store.dispatch('language', defaultLang)

  new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
  }).$mount('#app')
}

runApp().then()
