import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from './store'

Vue.use(VueI18n)

async function loadLocaleMessages() {
  if (process.env.NODE_ENV === 'test') {
    return {}
  }

  let messages = {}
  const locales = store.getters['translations']

  if (!Object.keys(locales).length) {
    const envSettings = store.getters['env']
    const translations = envSettings.CDN_TRANSLATION_URLS

    await Promise.all(
      Object.keys(translations).map(async lang => {
        if ([envSettings.VUE_APP_I18N_FALLBACK_LOCALE, envSettings.VUE_APP_I18N_LOCALE].some(item => item === lang)) {
          const response = await fetch(translations[lang])
          messages[lang] = await response.json()
        }
      })
    )

    await store.dispatch('storeTranslations', messages)
  } else {
    messages = locales
  }

  return messages
}

function setLocale() {
  const envSettings = store.getters['env']

  return localStorage.getItem('landing_language') || envSettings?.VUE_APP_I18N_LOCALE || 'en_GB'
}

function setFallbackLocale() {
  const envSettings = store.getters['env']

  return envSettings?.VUE_APP_I18N_FALLBACK_LOCALE || 'en_GB'
}

export const i18nLocale = async () => {
  return new VueI18n({
    locale: setLocale(),
    fallbackLocale: setFallbackLocale(),
    messages: await loadLocaleMessages(),
  })
}
