import axios from '@/api'

const RESOURCE_SETTINGS = '/settings'

export default {
  async getEnvironmentSettings() {
    const url = `${RESOURCE_SETTINGS}/client`
    const params = { client_type: 'landing' }

    return await axios
      .get(url, { params })
      .then(response => {
        return response.data
      })
      .catch(error => {
        throw error
      })
  },
}
