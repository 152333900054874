import store from '@/store'
import Vue from 'vue'

export default {
  getBrowserLanguage() {
    const lang = store.getters['language']
    if (lang) {
      return lang
    }

    if (window.navigator.userLanguage) {
      Vue.prototype.$moment.locale(window.navigator.userLanguage)

      return window.navigator.userLanguage
    }
    Vue.prototype.$moment.locale(window.navigator.language)

    return window.navigator.language
  },

  getBrowserTimeFormat() {
    return Vue.prototype.$moment().locale(this.getBrowserLanguage())._locale._longDateFormat.LT
  },

  getBrowserDateFormat() {
    return Vue.prototype.$moment().locale(this.getBrowserLanguage())._locale._longDateFormat.L
  },

  setDateToBrowserTimeFormat(input) {
    return Vue.prototype.$moment(input).locale(this.getBrowserLanguage()).format(this.getBrowserTimeFormat())
  },

  setDateToBrowserDateFormat(input) {
    return Vue.prototype.$moment(input).locale(this.getBrowserLanguage()).format(this.getBrowserDateFormat())
  },

  moment() {
    return Vue.prototype.$moment
  },

  getMonthNumber() {
    return Vue.prototype.$moment().format('M')
  },

  getLocaleStore(url, lang, env) {
    let image
    try {
      image = require('../' + url + lang + '.svg')
    } catch (err) {
      image = require('../' + url + env.VUE_APP_I18N_FALLBACK_LOCALE + '.svg')
    }

    return image
  },
}
