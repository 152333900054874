import axios from 'axios'
// Vuex
import store from '@/store'
import { isEmpty } from 'lodash-es'
import { i18nLocale } from '@/i18n'

const instance = axios.create({})

instance.interceptors.request.use(config => {
  let locale = ''
  const envSettings = store.getters['env']
  const token = store.getters['token']
  if (config.url === '/settings/client') {
    config.headers = {
      'Application-token': process.env.VUE_APP_DEFAULT_API_TOKEN,
    }
  } else {
    locale = localStorage.getItem('landing_language')
      ? localStorage.getItem('landing_language')
      : envSettings.VUE_APP_I18N_LOCALE

    config.headers = {
      'Application-token': envSettings.VUE_APP_API_APPLICATION_TOKEN,
    }

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
  }

  if (!isEmpty(locale)) {
    locale = `/${locale}`
  }

  const url = apiBaseUrl(envSettings?.VUE_APP_API_BASE_URL)

  config.baseURL = `${url}${locale}/api/v7`

  return config
})

instance.interceptors.response.use(
  function (response) {
    return response
  },
  async function (error) {
    const i18n = await i18nLocale()

    error.response.data.user_message = error.response.data.user_message ?? i18n.t(`global_error_somethingWentWrong`)

    return Promise.reject(error)
  }
)

export default instance

const apiBaseUrl = (url = null) => {
  url = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_BASE_URL : url

  return isEmpty(url) ? window.location.origin : url
}
